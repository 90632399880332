import React from 'react';
import Btn, { BtnProps } from '@/polymorphics/Btn';

export type ShareBtn<D extends React.ElementType = 'button'> = BtnProps<
  D,
  {
    linkType?: 'Linkedin' | 'Twitter' | 'Link' | 'Facebook';
    href?: string;
  }
>;

export default function ShareBtn({ linkType, ...props }: ShareBtn) {
  return (
    <Btn
      variant="circle"
      size="xs"
      color="white"
      iconType={linkType ? 'share' + linkType : undefined}
      iconSize="lg"
      // target="_blank"
      {...props}
    />
  );
}
