'use client';

import { PageEntryTypes } from '@/lib/parse/entries';
import Flex, { FlexProps } from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import { rawColorValues } from '@/style/partials/palette.css';
import React, { useState } from 'react';
import ShareBtn from './ShareBtn';

export type ShareButtons<D extends React.ElementType = 'div'> = FlexProps<
  D,
  {
    entry: PageEntryTypes;
  }
>;

export default function ShareButtons({ entry, ...props }: ShareButtons) {
  const [copy, setCopy] = useState<undefined | string>(undefined);

  const { pageTitle, url } = entry ?? {};

  const handleClick = () => {
    navigator.clipboard.writeText(`${url}`);
    setCopy('Link copied');
    setTimeout(() => setCopy(undefined), 2000);
  };

  return (
    <Flex align="center" justify="end" cx={{ columnGap: '2', mB: '5' }} {...props}>
      <ShareBtn
        variant={copy ? 'pill' : 'circle'}
        cx={{ cursor: 'pointer' }}
        style={{ border: `1px solid ${rawColorValues.mistBlue}` }}
        linkType={!copy ? 'Link' : undefined}
        onClick={handleClick}>
        {copy ? (
          <Txt as="span" variant="xs" cx={{ pX: '0.5' }}>
            {copy}
          </Txt>
        ) : null}
      </ShareBtn>
      <ShareBtn linkType="Facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} />
      <ShareBtn linkType="Twitter" href={`http://twitter.com/share?text=${pageTitle}&url=${url}`} />
      <ShareBtn
        linkType="Linkedin"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}
      />
    </Flex>
  );
}
